import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { SidenavService } from '../services/sidenav.service';
import { MatSidenav } from '@angular/material';
import { AuthService } from '../../../services/auth/auth.service';
import { AppService } from '../../../services/app/app.service';

@Component({
  selector: 'main-container-wrapper',
  templateUrl: './main-container-wrapper.component.html',
  styleUrls: ['./main-container-wrapper.component.scss'],
})
export class MainContainerWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav', {static: false}) mobileSidenav: MatSidenav;

  menuCollapsed: boolean;
  menuCollapseSubscription: Subscription;
  sidebarWidth: string;
  sidebarCollapsedWidth: string;
  userName: string;

  constructor(
    private sidenavService: SidenavService,
    private authService: AuthService,
    private appService: AppService
  ) {
    this.menuCollapsed = false;
    this.sidebarWidth = '280px';
    this.sidebarCollapsedWidth = '60px';
  }

  ngOnInit() {
    this.menuCollapseSubscription = this.sidenavService.collapsed.subscribe(
      (value) => this.menuCollapsed = value
    );

    const user = JSON.parse(localStorage.getItem('usuario'));
    if (user) {
      this.userName = user.nome;
    }
  }

  tracking($event) {
    this.appService.scrollTracker($event);
  }

  ngOnDestroy() {
    if (this.menuCollapseSubscription) {
      this.menuCollapseSubscription.unsubscribe();
    }
  }

  closeMobileSidenav() {
    this.mobileSidenav.close();
  }

  editProfile() {
    window.location.href = `${environment.pluriConnect.front}/app/perfil?redirectTo=${btoa(window.location.href)}`;
  }

  logout() {
    this.authService.logout();
  }
}
