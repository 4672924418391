export class TableEstoqueContants {
    static propName(usuarioLiberador: boolean): string[] {
        const mainFields: string[] = [
            'clie_nome_fantasia',
            'pedido_disponibilidade_pedido_critico',
            'pedido_disponibilidade_passo',
            'ped_id',
            'inst_uf',
            'pedido_disponibilidade_prioridade_provisionamento',
        ];
        if (usuarioLiberador) {
            mainFields.push(...[
                'pedido_disponibilidade_prioridade_ajustada',
                'pedido_disponibilidade_simulacao_escolhida',
                'pedido_disponibilidade_passo_simulacao_1',
                'pedido_disponibilidade_passo_simulacao_2',
                'pedido_disponibilidade_passo_simulacao_3',
                'pedido_disponibilidade_passo_simulacao_4',
                'pedido_disponibilidade_passo_simulacao_5',
                'pedido_disponibilidade_pedido_liberado',]);
        }


        mainFields.push(...[
            'pedido_disponibilidade_pedido_suspenso',
            'inst_codigo',
            'inst_nfantasia',
            'inst_localidade',
            'prod_cate_id',
            'prod_cate_descricao',
            'pedido_disponibilidade_data_inicio_aguardando_recebimento',
            'pedido_disponibilidade_data_termino_aguardando_estoque',
            'pedido_disponibilidade_data_processamento',
            'pedido_disponibilidade_data_maxima_provisionamento',
            'pedido_disponibilidade_id',
        ]);

        return mainFields;

    }

    static displayName(usuarioLiberador: boolean): string[] {
        let mainNames: string[] = [
            'CLIENTE',
            "PED. CRÍTICO",
            "PASSO",
            'PEDIDO',
            'INST. UF',
            'PRIO. PROVISIO.',];

        if (usuarioLiberador) {
            mainNames.push(...[
                'PRIO. AJUST.',
                "SIMU. ESCOLH.",
                'SIMU. 1',
                'SIMU. 2',
                'SIMU. 3',
                'SIMU. 4',
                'SIMU. 5',
                "PED. LIBERADO",
            ]);
        }

        mainNames.push(...[
            'PED. SUSPENSO',
            'INST. CÓDIGO',
            'INSTITUIÇAO',
            'INST. CIDADE',
            'CATEG. CÓDIGO',
            'CATEGORIA',
            "Dt. INI. AGUARD. RECEB.",
            "DT. FIM  AGUARD. ESTO.",
            "DT. PROCESSAMENTO",
            "DT. MAX. PROVISIONAMENTO",
            'CÓDIGO DISPON.',
        ]);
        return mainNames;
    }

    static cellSizes(usuarioLiberador: boolean): string[] {
        let mainSizes: string[] = [
            '250px',
            "130px",
            "120px",
            '120px',
            '120px',
            '170px',
        ];

        if (usuarioLiberador) {
            mainSizes.push(...[
                '180px',
                '120px',
                '120px',
                '120px',
                '120px',
                '120px',
                '120px',
                '180px',
            ]);
        }

        mainSizes.push(...[
            '150px',
            '120px',
            '250px',
            '200px',
            "100px",
            '250px',
            "180px",
            "180px",
            "180px",
            "180px",
            "180px",
            "180px",
            '120px',]);
        return mainSizes;
    }
}