import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { CHAVE_ERP_COSMOS } from 'src/app/constants/chaves-sistemas';

@Injectable()
export class AuthService {

  constructor() { }

  getUser(token: String, usua_tipo_id: String, inst_codigo: String) {
    const params = {
      rotina_curta: 1,
      usua_tipo_id: usua_tipo_id,
      chave_sistema: CHAVE_ERP_COSMOS
    };

    const headers = {
      'Inst-Codigo': inst_codigo,
      'Authorization': token,
    };

    const instance = axios.create({
      baseURL : environment.pluriConnect.back
    });

    return instance.get(`v2/usuarios`, { params: params, headers: headers });
  }

  logout() {
    localStorage.clear();
    window.open(`${environment.pluriConnect.front}/logout`, '_self');
  }

  registerStorage(user: any, usua_perfil_ativo: any, profileIndex: any) {
    localStorage.setItem('usua_perfil_ativo', JSON.stringify(usua_perfil_ativo));
    localStorage.setItem('usuario', JSON.stringify(user));
    localStorage.setItem('perfil_ativo', profileIndex);
  }

}
