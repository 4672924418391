import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TableListColumns } from '../../../interfaces/table-list.interface';
import { AppService } from 'src/app/services/app/app.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'app-table-list',
  styleUrls: ['table-list.component.scss'],
  templateUrl: 'table-list.component.html'
})
export class TableListComponent {
  tableColumns: TableListColumns;
  tableRows: any;
  tableMaxHeight: string = '100%';
  tableOverflowY: string = 'hidden !important'

  @Input('maxHeight') set maxHeight(value: number) {
    if (value) {
      this.tableMaxHeight = value.toString() + 'px';
      this.tableOverflowY = 'auto';
    }
  }

  @Input('columns') set columns(value: TableListColumns) {
    if (value && value.propName.indexOf('options') < 0) {
      value.propName.push('options');
      value.displayName.push('');
      value.hide.xs.push(false);
      value.hide.sm.push(false);
      value.hide.md.push(false);
      value.size.push('100px');
      this.tableColumns = value;
    } else {
      this.tableColumns = value;
    }
  }

  @Input('rows') set rows(value: any) {
    if (value) {
      for (let i = 0; i < value.length; i++) {
        value[i].index = i;
        value[i].showOptions = true;
      }
      this.tableRows = value;
    }
  }

  @Output() viewRow: EventEmitter<any>;
  @Output() actionRow: EventEmitter<any>;

  constructor(private appService: AppService) {
    this.viewRow = new EventEmitter();
    this.actionRow = new EventEmitter();
  }

  changeStyle($event, row) {
    if ($event.type === 'mouseover') {
      this.tableRows[row.index].showOptions = true;
    } else if ($event.type === 'mouseout') {
      this.tableRows[row.index].showOptions = false;
    }
  }

  onView(row) {
    this.viewRow.emit(row);
  }

  onAction(type, row) {
    this.actionRow.emit({
      type,
      data: row
    });
  }

}
