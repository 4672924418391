import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DisponibilidadeEstoqueComponent } from './components/disponibilidade-estoque/disponibilidade-estoque.component';
import { DisponibilidadeDetalhadaComponent } from './components/disponibilidade-detalhada/disponibilidade-detalhada.component';
import { PermissionGuard } from 'src/app/guards/permission/permission.guard';
import { PERMISSOES } from 'src/app/constants/permissoes';


export const DisponibilidadeEstoqueRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'listagem',
                component: DisponibilidadeEstoqueComponent,
                canActivate: [PermissionGuard],
                data: {
                    permissions: [
                        PERMISSOES.LISTAGEM_DISPONIBILIDADE
                    ]
                },
            },
            {
                path: ':id/detalhamento',
                component: DisponibilidadeDetalhadaComponent,
                canActivate: [PermissionGuard],
                data: {
                    permissions: [
                        PERMISSOES.DETALHAMENTO_DISPONIBILIDADE
                    ]
                },
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(DisponibilidadeEstoqueRoutes)],
    exports: [RouterModule]
})

export class DisponibilidadeEstoqueRouting { }
