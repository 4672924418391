import { MainContainerWrapperModule } from './main-container-wrapper/main-container-wrapper.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule, MatMenuModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FullscreenLoaderService } from './services/fullscreen-loader.service';
@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatButtonModule,
    MainContainerWrapperModule,
    RouterModule,
    FormsModule
  ],
  providers: [
    FullscreenLoaderService
  ],
})
export class SharedModule { }
