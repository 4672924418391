import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DialogSelectProdutoComponent } from './dialog-select-produto.component';
import { DialogSelectProdutoService } from './dialog-select-produto.service';
import { FlexLayoutModule } from '@angular/flex-layout';

import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSnackBarModule,
  ],
  declarations: [DialogSelectProdutoComponent],
  exports: [DialogSelectProdutoComponent],
  entryComponents: [DialogSelectProdutoComponent],
  providers: [DialogSelectProdutoService]
})
export class DialogSelectProdutoModule { }
