import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class AgregadoresService {

  constructor() { }

  public buscarAgregadores(filtro: any): Promise<any> {
    return axios.get(`agregadores/listar`, {
      params : filtro
    });
  }

  public criarAgregadores(dados: any): Promise<any> {
    return axios.post(`agregadores/criar`, dados);
  }

  public desativarAgregador(dados: any): Promise<any> {
    return axios.put(`agregadores/desativar`, dados);
  }

}
