import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { Functions } from '../../../../app.functions';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {

  usuario: string;
  foto: string;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    this.usuario = Functions.truncate(usuario.usua_nome, 20);
    if (usuario.usua_foto_miniatura) {
      this.foto = usuario.usua_foto_miniatura;
    } else {
      this.foto = usuario.usua_foto;
    }
  }

  logoutSystem() {
    this.authService.logout();
  }

  changePerfil() {
    window.location.href = `${environment.pluriConnect.front}/app/perfil?redirectTo=${btoa(window.location.href)}`;
  }

}
