import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/services/app/app.service';
import { DisponibilidadeEstoqueService } from 'src/app/views/shared/services/disponibilidade-estoque.service';
import { IDisponibilidadeEstoque } from '../../interfaces/disponibilidade_estoque.interface';
import { Utils } from 'src/app/views/shared/utils';
import { Location } from '@angular/common';
import { TableListColumns } from 'src/app/interfaces/table-list.interface';
import { TableItemEstoque } from '../../constants/table-item.constants';
import { IDisponibilidadeItemComposicao } from '../../interfaces/diponibilidade_item_composicao.interface';
import { TableComposicao } from '../../constants/table-composicao.constants';
import { PERMISSOES } from 'src/app/constants/permissoes';

@Component({
  selector: 'app-disponibilidade-detalhada',
  templateUrl: './disponibilidade-detalhada.component.html',
  styleUrls: ['./disponibilidade-detalhada.component.scss']
})
export class DisponibilidadeDetalhadaComponent implements OnInit {

  disponibilidadeForm: FormGroup;
  pedidoDisponibilidadeId: number;
  disponibilidade: IDisponibilidadeEstoque;
  processando: boolean = true;

  temItens: boolean = false;
  listaColunasItens: TableListColumns;
  listaLinhasItens: Array<any>;

  temComposicao: boolean = false;
  listaColunasComposicao: TableListColumns;
  listaLinhasComposicao: Array<any>;

  composicaoSelecionada: IDisponibilidadeItemComposicao[] = [];

  usuarioLiberador: boolean = false;

  constructor(private route: ActivatedRoute,
    private location: Location,
    private router: ActivatedRoute,
    private disponibilidadeService: DisponibilidadeEstoqueService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private appService: AppService
  ) {
    this.usuarioLiberador = this.appService.verifyPermission(PERMISSOES.DISPONIBILIDADE_LIBERADOR);

    this.disponibilidadeForm = this.fb.group({
      ped_id: '',
      inst_nfantasia: '',
      inst_localidade: '',
      inst_uf: '',
      prod_cate_descricao: '',
      pedido_disponibilidade_pedido_suspenso: '',
      pedido_disponibilidade_prioridade_provisionamento: '',
      pedido_disponibilidade_pedido_critico: '',
      pedido_disponibilidade_pedido_liberado: '',
      pedido_disponibilidade_prioridade_ajustada: '',
      pedido_disponibilidade_simulacao_escolhida: '',
      pedido_disponibilidade_passo_simulacao_1: '',
      pedido_disponibilidade_passo_simulacao_2: '',
      pedido_disponibilidade_passo_simulacao_3: '',
      pedido_disponibilidade_passo_simulacao_4: '',
      pedido_disponibilidade_passo_simulacao_5: '',
      pedido_disponibilidade_data_termino_aguardando_estoque: '',
      pedido_disponibilidade_data_inicio_aguardando_recebimento: '',
      pedido_disponibilidade_data_processamento: '',
      pedido_disponibilidade_data_maxima_provisionamento: '',
      pedido_disponibilidade_passo: '',
    });

    this.listaColunasItens = {
      propName: TableItemEstoque.propName(this.usuarioLiberador),
      displayName: TableItemEstoque.displayName(this.usuarioLiberador),
      hide: {
        xs: [false, false, false, false],
        sm: [false, false, false, false],
        md: [false, false, false, false]
      },
      size: TableItemEstoque.cellSizes(this.usuarioLiberador)
    };

    this.listaColunasComposicao = {
      propName: TableComposicao.propName(this.usuarioLiberador),
      displayName: TableComposicao.displayName(this.usuarioLiberador),
      hide: {
        xs: [false, false, false, false],
        sm: [false, false, false, false],
        md: [false, false, false, false]
      },
      size: TableComposicao.cellSizes(this.usuarioLiberador)
    };
  }

  ngOnInit() {
    this.pedidoDisponibilidadeId = Number.parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.getDisponibilidadeByID();
  }


  private async getDisponibilidadeByID() {
    this.processando = true;
    const disponibilidadeResult = await this.disponibilidadeService.buscarDisponibilidadePorID(this.pedidoDisponibilidadeId);
    this.disponibilidade = disponibilidadeResult.data.data;
    this.loadFieldValues();

  }

  private generateBooleanField(fieldValue: any) {
    if (fieldValue != null) {
      return fieldValue.toString() == '1';
    } else {
      return false;
    }
  }

  private loadFieldValues() {
    this.disponibilidadeForm.patchValue({
      ped_id: this.disponibilidade.ped_id,
      inst_nfantasia: this.disponibilidade.inst_nfantasia,
      inst_localidade: this.disponibilidade.inst_localidade,
      inst_uf: this.disponibilidade.inst_uf,
      prod_cate_descricao: this.disponibilidade.prod_cate_descricao,
      pedido_disponibilidade_pedido_suspenso: this.generateBooleanField(this.disponibilidade.pedido_disponibilidade_pedido_suspenso),
      pedido_disponibilidade_prioridade_provisionamento: this.disponibilidade.pedido_disponibilidade_prioridade_provisionamento,
      pedido_disponibilidade_pedido_critico: this.generateBooleanField(this.disponibilidade.pedido_disponibilidade_pedido_critico),
      pedido_disponibilidade_pedido_liberado: this.generateBooleanField(this.disponibilidade.pedido_disponibilidade_pedido_liberado),
      pedido_disponibilidade_prioridade_ajustada: this.disponibilidade.pedido_disponibilidade_prioridade_ajustada,
      pedido_disponibilidade_simulacao_escolhida: this.disponibilidade.pedido_disponibilidade_simulacao_escolhida,
      pedido_disponibilidade_passo_simulacao_1: this.disponibilidade.pedido_disponibilidade_passo_simulacao_1,
      pedido_disponibilidade_passo_simulacao_2: this.disponibilidade.pedido_disponibilidade_passo_simulacao_2,
      pedido_disponibilidade_passo_simulacao_3: this.disponibilidade.pedido_disponibilidade_passo_simulacao_3,
      pedido_disponibilidade_passo_simulacao_4: this.disponibilidade.pedido_disponibilidade_passo_simulacao_4,
      pedido_disponibilidade_passo_simulacao_5: this.disponibilidade.pedido_disponibilidade_passo_simulacao_5,
      pedido_disponibilidade_data_termino_aguardando_estoque: Utils.formatDate(this.disponibilidade.pedido_disponibilidade_data_termino_aguardando_estoque),
      pedido_disponibilidade_data_inicio_aguardando_recebimento: Utils.formatDate(this.disponibilidade.pedido_disponibilidade_data_inicio_aguardando_recebimento),
      pedido_disponibilidade_data_processamento: Utils.formatDateTime(this.disponibilidade.pedido_disponibilidade_data_processamento),
      pedido_disponibilidade_data_maxima_provisionamento: Utils.formatDate(this.disponibilidade.pedido_disponibilidade_data_maxima_provisionamento),
      pedido_disponibilidade_passo: this.disponibilidade.pedido_disponibilidade_passo,
    });

    this.listaLinhasItens = this.formatarLinhasTabelaItens();
    this.processando = false;

  }

  onVoltarClick() {
    this.location.back();
  }

  formatarLinhasTabelaItens(): Array<any> {
    if (this.disponibilidade.itens != null && this.disponibilidade.itens.length > 0) {
      this.temItens = true;
      return this.disponibilidade.itens;
    } else {
      this.temItens = false;
      return null;
    }
  }

  async onClickRow(event) {
    this.composicaoSelecionada = event.composicao;

    this.listaLinhasComposicao = this.formatarLinhasTabelaComposicao(this.composicaoSelecionada);
  }

  formatarLinhasTabelaComposicao(composicao: IDisponibilidadeItemComposicao[]): Array<any> {
    if (composicao != null && composicao.length > 0) {
      this.temComposicao = true;
      return composicao;
    } else {
      this.temComposicao = false;
      return null;
    }
  }


}
