import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogSelectProdutoService } from './dialog-select-produto.service';

@Component({
  selector: 'app-dialog-select-produto',
  templateUrl: './dialog-select-produto.component.html',
  styleUrls: ['./dialog-select-produto.component.scss']
})
export class DialogSelectProdutoComponent implements OnInit {

  public listaProdutos: any = [];
  public displayedColumns: string[] = ['codigo_produto', 'codigo_barra', 'descricao_produto'];

  public filtro;
  public start = 0;
  public limit = 30;
  public end = this.limit + this.start;
  private table = false;

  private paginaAtual_produtos = 1;
  private totalPaginas_produtos = null;

  constructor(
    public dialogRef: MatDialogRef<DialogSelectProdutoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: ChangeDetectorRef,
    private dialogSelectProdutoService: DialogSelectProdutoService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  private updateIndex(): void {
    this.start = this.end;
    this.end = this.limit + this.start;
    this.ref.detectChanges();
  }

  public buscar(): void {
    this.buscarProduto();
  }

  upScroll() {
    const divTable = document.getElementsByClassName('tabela-dialog-container').item(0);
    if (divTable) {
      divTable.scrollTo(0, 0);
    }
  }

  public onTableScroll(e): void {
    this.ref.detectChanges();
    const tableHeight = document.getElementsByClassName('tabela-dialog-content')[0].clientHeight;
    const altura = e.srcElement.offsetHeight;
    const scroll = e.srcElement.scrollTop;
    const scrollBottom = tableHeight - altura - scroll;
    if (scrollBottom === 0) {
      if (this.totalPaginas_produtos > this.paginaAtual_produtos) {
        this.buscarProduto(true);
      }
    }
  }

  public selecionar(row: any): void {
    if (row) {
      this.dialogRef.close(row);
    }
  }

  public buscarProduto(update: boolean = false): void {
    if (update) {
      this.start = this.end;
      this.end = this.limit + this.start;

      this.dialogSelectProdutoService.buscarProduto({
        filtro : (typeof this.filtro === 'string') ? this.filtro.toUpperCase() : this.filtro,
        start: this.start,
        limit: this.limit
      }).then(res => {
        if (res.data.success === true) {
          const produtos = res.data.data.produtos;
          this.paginaAtual_produtos = res.data.data.pagina_atual;
          this.totalPaginas_produtos = res.data.data.total_paginas;

          this.listaProdutos = [...this.listaProdutos, ...produtos];
          this.ref.detectChanges();
          this.table = true;
        } else {
          this._snackBar.open(res.data.message, 'Fechar', {
            duration: 5000,
            horizontalPosition : 'center',
            verticalPosition: 'bottom'
          });
        }
      }).catch(err => {
        if (err.status === 409) {
          this._snackBar.open('Não há mais produto para mostrar.', 'Fechar', {
            duration: 5000,
            horizontalPosition : 'center',
            verticalPosition: 'bottom'
          });
        } else {
          this._snackBar.open(err.data.message, 'Fechar', {
            duration: 5000,
            horizontalPosition : 'center',
            verticalPosition: 'bottom'
          });
        }
      });

    } else {
      this.start = 0;
      this.end = this.limit + this.start;
      this.dialogSelectProdutoService.buscarProduto({
        filtro : (typeof this.filtro === 'string') ? this.filtro.toUpperCase() : this.filtro,
        start: this.start,
        limit: this.limit
      }).then(res => {
        if (res.data.success === true) {
          const produtos = res.data.data.produtos;
          this.paginaAtual_produtos = res.data.data.pagina_atual;
          this.totalPaginas_produtos = res.data.data.total_paginas;
          this.listaProdutos = produtos;
          this.ref.detectChanges();
          this.table = true;
        } else {
          this._snackBar.open(res.data.message, 'Fechar', {
            duration: 5000,
            horizontalPosition : 'center',
            verticalPosition: 'bottom'
          });
        }
      }).catch(err => {
        this.listaProdutos = [];
        this.table = true;
        this._snackBar.open(err.data.message, 'Fechar', {
          duration: 5000,
          horizontalPosition : 'center',
          verticalPosition: 'bottom'
        });
      });
    }

  }

}
