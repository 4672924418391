import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintBoardService {

  private _toPrint = new BehaviorSubject<any>([]);
  readonly toPrint = this._toPrint.asObservable();

  constructor() { }

  get getToPrint() {
    return this._toPrint.asObservable();
  }

  set setToPrint(valor) {
    this._toPrint.next(valor);
  }

}
