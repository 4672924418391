import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogSelectProdutoComponent } from './dialog-select-produto.component';
import axios from 'axios';

@Injectable()
export class DialogSelectProdutoService {

  private _nomeProduto = new BehaviorSubject<any>([]);
  readonly nomeProduto = this._nomeProduto.asObservable();

  public get getNomeProduto() {
    return this._nomeProduto.asObservable();
  }

  public set setNomeProduto(valor) {
    this._nomeProduto.next(valor);
  }

  constructor(
    public dialog: MatDialog
  ) { }

  public openDialog(): Observable<any> {
    const dialogRef = this.dialog.open(DialogSelectProdutoComponent, {
      maxHeight: '90vh',
      minWidth: '50vw'
    });
    return dialogRef.afterClosed();
  }

  public buscarProduto(dados) {
    return axios.get('/produto/listar', { params: dados });
  }

}
