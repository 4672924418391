import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: Array<any>, value: any, attribute: string = null): any {
    let filtredItems = items;
    value = value.toString();
    if (value) {
      value = this.removeAccents(value).toLowerCase();
      if (items instanceof Array) {
        filtredItems = items.filter((elem) => {
          if (attribute) {
            return this.removeAccents(elem[`${attribute}`].toString().toLowerCase()).includes(value);
          } else {
            return this.removeAccents(elem.toString().toLowerCase()).includes(value);
          }
        });
      }
    }

    return filtredItems;
  }

  private removeAccents(query) {
    let expression = null;

    const lettersMap = {
      a: /[\xE0-\xE6]/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
      c: /\xE7/g,
      n: /\xF1/g
    };

    for (let letter in lettersMap) {
      expression = lettersMap[letter];
      query = query.replace(expression, letter);
    }

    return query;
  }
}
