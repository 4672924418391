import { Injectable } from '@angular/core';

export const beep = {
  DUPLICADO : 'assets/sounds/beep-duplicado.mp3',
  ERRO : 'assets/sounds/beep-erro.mp3',
  OK : 'assets/sounds/beep-ok.mp3',
  PARCIAL : 'assets/sounds/beep-parcial.mp3'
};

@Injectable({
  providedIn: 'root'
})
export class BeepService {


  constructor() { }

  public play(type: 'duplicado' | 'erro' | 'ok' | 'parcial'): void {
    let audio;
    switch (type) {
      case 'duplicado':
        audio = new Audio(beep.DUPLICADO);
        audio.play();
        break;
      case 'erro':
        audio = new Audio(beep.ERRO);
        audio.play();
        break;
      case 'ok':
        audio = new Audio(beep.OK);
        audio.play();
        break;
      case 'parcial':
        audio = new Audio(beep.PARCIAL);
        audio.play();
        break;
    };
  }

}
