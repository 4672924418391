import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
    providedIn: 'root'
})
export class DisponibilidadeEstoqueService {
    constructor() { }

    public listarDisponibilidade(filtro: any): Promise<any> {

        return axios.get(`/estoque/consultar_disponibilidade`, { params: filtro });
    }

    public buscarDisponibilidadePorID(id: any): Promise<any> {

        return axios.get(`/estoque/consultar_disponibilidade/${id}`)
    }

    public liberarPedido(pedidoDisponibilidadeId: string, simulacaoEscolhida: string): Promise<any> {
        const params = {
            pedido_disponibilidade_id: pedidoDisponibilidadeId,
            simulacao_escolhida: simulacaoEscolhida,
        };
        return axios.put(`estoque/liberar_pedido`, params)
    }

    public listarInstituicoes(): Promise<any> {

        return axios.get(`/estoque/instituicoes`)
    }
}
