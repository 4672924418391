import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SemAcessoComponent } from './sem-acesso.component';
import { FullscreenLoaderService } from '../shared/services/fullscreen-loader.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SemAcessoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  exports: [
    SemAcessoComponent,
  ],
  providers: [FullscreenLoaderService],
})
export class SemAcessoModule { }
