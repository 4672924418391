import { FlexLayoutModule } from '@angular/flex-layout';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GerenciadorAgregadoresComponent } from './gerenciador-agregadores.component';
import { GerenciadorAgregadoresRoutes } from './gerenciador-agregadores.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TabelaAgregadoresModule } from './../../shared/tabela-agregadores/tabela-agregadores.module';
import { CriarAgregadorComponent } from '../criar-agregador/criar-agregador.component';
import { EmpresasService } from '../../shared/services/empresas.service';
import { AgregadoresService } from '../../shared/services/agregadores.service';
import { DialogSelectProdutoModule } from '../../shared/dialog-select-produto/dialog-select-produto.module';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BeepService } from '../../../services/beep/beep.service';
import { PrintBoardModule } from '../../shared/print-board/print-board.module';
import { ProdutoValidator } from '../../../validators/produtoValidator/produtoValidator.validator';

@NgModule({
    declarations: [
        GerenciadorAgregadoresComponent,
        CriarAgregadorComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(GerenciadorAgregadoresRoutes),
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        FlexLayoutModule,
        MatCardModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        TabelaAgregadoresModule,
        MatTooltipModule,
        DialogSelectProdutoModule,
        MatSnackBarModule,
        PrintBoardModule
    ],
    exports: [
        GerenciadorAgregadoresComponent,
    ],
    entryComponents: [
        CriarAgregadorComponent
    ],
    providers: [EmpresasService, AgregadoresService, BeepService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GerenciadorAgregadoresModule { }
