import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DialogSelectProdutoService } from 'src/app/views/shared/dialog-select-produto/dialog-select-produto.service';
@Injectable()
export class ProdutoValidator {

    private servicoProduto;

    constructor(public dialogSelectProdutoService: DialogSelectProdutoService) {
        this.servicoProduto = dialogSelectProdutoService;
    }

    public verificaProduto(control: AbstractControl) {
        const valor = control.value;
        if (valor.length >= 1) {
            return this.dialogSelectProdutoService.buscarProduto({
                filtro : valor,
                start: 0,
                limit: 1
            }).then(success => {
                const produtos = success.data.data.produtos;
                if (produtos.length > 0) {
                    const filter = produtos.filter(item => {
                        return item.codigo_produto === valor;
                    });
                    if (filter.length > 0) {
                        control.setErrors(null);
                        this.dialogSelectProdutoService.setNomeProduto = filter[0];
                        return null;
                    } else {
                        control.setErrors({
                            produtoValid: true
                        });
                        control.markAllAsTouched();
                        this.dialogSelectProdutoService.setNomeProduto = '';
                        return { produtoValid: true };
                    }
                } else {
                    control.setErrors({
                        produtoValid: true
                    });
                    control.markAllAsTouched();
                    this.dialogSelectProdutoService.setNomeProduto = '';
                    return { produtoValid: true };
                }
            }).catch(() => {
                control.setErrors({
                    produtoValid: true
                });
                control.markAllAsTouched();
                this.dialogSelectProdutoService.setNomeProduto = '';
                return { produtoValid: true };
            });
        }
    }
}
