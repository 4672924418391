import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'network-check',
  templateUrl: './network-check.component.html',
  styleUrls: ['./network-check.component.scss']
})
export class NetworkCheckComponent implements OnInit {

  public noNetworkDialog: boolean = false;
  private timeLapse: number = 5000;
  public time: number = 0;
  public timeText: string = '3 segundos';

  private contador = this.timeLapse / 1000;
  private contadorProgressivo = 0;

  private intervalo = () => {
    this.contador = this.timeLapse / 1000;
    this.contadorProgressivo = 0;
    return setInterval(() => {
      this.timeText = (this.contador === 1) ? this.contador + ' segundo' : this.contador + ' segundos';
      if (this.contador === 0) {
        this.contador = this.timeLapse / 1000;
        this.contadorProgressivo = 100;
      } else if (this.contador === (this.timeLapse / 1000)) {
        this.contador--;
        this.contadorProgressivo = 0;
      } else {
        this.contador--;
        this.contadorProgressivo++;
      }
      this.time = (this.contadorProgressivo * 100) / (this.timeLapse / 1000);
    }, 1000);
  }

  public get isOnline() {
    return navigator.onLine;
  }
  public get isOffline() {
    return !navigator.onLine;
  }

  constructor() {
    this.execute();
    setInterval(() => {
      if (this.noNetworkDialog === this.isOffline) {
        this.intervalo();
      } else {
        clearInterval(this.intervalo());
      }
    }, 1000);
  }

  ngOnInit() {
  }

  private execute() {
    setInterval(() => {
      this.noNetworkDialog = this.isOffline;
    }, this.timeLapse);
  }

}
