import { Component, Input, OnInit } from '@angular/core';
import * as jsBarcode from 'jsbarcode';
import { element } from 'protractor';

@Component({
  selector: 'barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit {

  @Input() public codigoBarras: string;
  public identify;

  constructor() {
  }

  ngOnInit() {
    this.generateNewID(this.codigoBarras);
    setTimeout(() => {
      jsBarcode('#' + this.identify, this.codigoBarras, {
        displayValue : true,
        width: 1.8,
        height: 40,
        fontSize: 18,
        fontOptions : 'bold'
      });
    }, 250);
  }

  public generateNewID(codigoBarras) {
    this.identify = 'id_' + codigoBarras;
  }

}
