import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  constructor() { }

  public listarEmpresas(): Promise<any> {
    return axios.get(`agregadores/carregar_empresas`);
  }

}
