import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {

  private collapseBehaviorSubject: BehaviorSubject<boolean>;
  collapsed: Observable<boolean>;

  constructor() {
    this.collapseBehaviorSubject = new BehaviorSubject(false);
    this.collapsed = this.collapseBehaviorSubject.asObservable();
  }

  collapse(collapse: boolean) {
    this.collapseBehaviorSubject.next(collapse);
  }

}
