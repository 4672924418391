import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss']
})
export class SidenavItemComponent implements OnInit {

  @Input() item: any;
  @Input() hideItem: boolean;
  @Input() collapsed: boolean;
  @Output() navegateEvent: EventEmitter<any>;

  constructor() {
    this.navegateEvent = new EventEmitter();
  }

  ngOnInit(): void { }

  navegate(item: any, subitem?: any) {
    this.navegateEvent.emit({ item, subitem });
  }

}
