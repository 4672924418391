import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import { InformacoesAdicionaisComponent } from './informacoes-adicionais/informacoes-adicionais.component';
import { DesativarDialogComponent } from './desativar-dialog/desativar-dialog.component';
import { TabelaAgregadoresComponent } from './tabela-agregadores.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    TabelaAgregadoresComponent,
    InformacoesAdicionaisComponent,
    DesativarDialogComponent,
  ],
  entryComponents: [
    InformacoesAdicionaisComponent,
    DesativarDialogComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    FlexLayoutModule
  ],
  exports: [
    TabelaAgregadoresComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TabelaAgregadoresModule { }
