import { IStatusPedido } from "../interfaces/status_pedido.interface";

export class StatusPedidoConst {

    static status_categ = {
        liberado: 'Pedido Liberado',
        provisionado: 'Pedido Provisionado',

    }

    static status: IStatusPedido[] = [
        {
            statusLabel: 'Provisionado',
            statusValue: '1',
            statusCateg: StatusPedidoConst.status_categ.provisionado,
        },
        {
            statusLabel: 'Não Provisionado',
            statusValue: '0',
            statusCateg: StatusPedidoConst.status_categ.provisionado
        },
        {
            statusLabel: 'Liberado',
            statusValue: '1',
            statusCateg: StatusPedidoConst.status_categ.liberado
        },
        {
            statusLabel: 'Não Liberado',
            statusValue: '0',
            statusCateg: StatusPedidoConst.status_categ.liberado
        },
    ];
}