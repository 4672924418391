import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '../../services/app/app.service';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  canActivate(
    snapshot: ActivatedRouteSnapshot
  ): boolean {
    const permissions = snapshot.data.permissions || [];

    let hasPermission = true;

    for (let i = 0; i < permissions.length; i++) {
      hasPermission = this.appService.verifyPermission(permissions[i]);
      if (hasPermission === false) {
        break;
      }
    }

    if (hasPermission === false) {
      this.router.navigateByUrl('/app/sem-acesso');
    }

    return hasPermission;
  }

}
