import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppService } from '../../services/app/app.service';

@Injectable()
export class HomeGuard implements CanActivate {

  constructor(
    private appService: AppService
  ) { }

  canActivate(): boolean {
    this.appService.redirectToHome();
    return true;
  }

}
