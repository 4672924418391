import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { IDisponibilidadeEstoque } from '../../interfaces/disponibilidade_estoque.interface';
import { IOrderValues } from '../../interfaces/order_values.interface';
import { FormControl } from '@angular/forms';



@Component({
  selector: 'app-ajustar-prioridade',
  templateUrl: './ajustar-prioridade.component.html',
  styleUrls: ['./ajustar-prioridade.component.scss']
})
export class AjustarPrioridadeComponent implements OnInit {

  orderValues: IOrderValues[] = [];

  formControlGroup: FormControl[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDisponibilidadeEstoque[],
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<AjustarPrioridadeComponent>,) {
    this.data.forEach(e => {
      e.pedido_disponibilidade_prioridade_ajustada = null

    });
  }

  ngOnInit() {
    this.generateOrderValues();
    this.data.forEach(_ => {
      this.formControlGroup.push(new FormControl(''));
    });
  }

  private generateOrderValues() {
    for (let index = 0; index < this.data.length; index++) {
      this.orderValues.push({
        value: (index).toString(),
        pedido_disponibilidade_id: null,
        teste: null,
      });
    }
  }

  onBackClick() {
    this.dialogRef.close(false);
  }

  private completeOrderSequence() {
    this.data.forEach(e => {
      if (e.pedido_disponibilidade_prioridade_ajustada == null
        || e.pedido_disponibilidade_prioridade_ajustada == '') {
        let order = this.orderValues.find((elem) => {
          return elem.pedido_disponibilidade_id == null;
        });
        e.pedido_disponibilidade_prioridade_ajustada = order.value;
        this.disableOrderValueItem(order.value, e.pedido_disponibilidade_id);
      }
    });
  }

  private disableOrderValueItem(itemValue, didponibilidadeId: number) {
    const index = this.orderValues.findIndex((elem) => {
      return elem.value === itemValue;
    });
    this.orderValues[index].pedido_disponibilidade_id = didponibilidadeId;

  }

  onConfirmClick() {
    this.completeOrderSequence();
    this.dialogRef.close(this.data);
  }

  cleanOldSelection(disponibilidadeId: number) {
    const index = this.orderValues.forEach(element => {
      if (element.pedido_disponibilidade_id == disponibilidadeId) {
        element.pedido_disponibilidade_id = null;
      }
    });
  }

  onSelectOrder($event, disponibilidade: IDisponibilidadeEstoque) {
    let indexSelecionado = this.data.findIndex((elem) => {
      return disponibilidade.pedido_disponibilidade_id === elem.pedido_disponibilidade_id;
    });
    if ($event.value.pedido_disponibilidade_id == null) {
      this.cleanOldSelection(disponibilidade.pedido_disponibilidade_id);
      this.data[indexSelecionado].pedido_disponibilidade_prioridade_ajustada = $event.value.value;
      this.disableOrderValueItem($event.value.value, disponibilidade.pedido_disponibilidade_id);
    } else {
      this.formControlGroup[indexSelecionado].setValue('');
      this.snackbar.open("Valor ja utilizado, por favor escolha outro!", 'FECHAR', { duration: 3500 });
    }
    console.log(this.orderValues);





  }
}




