export class TableItemEstoque {
    static propName(usuarioLiberador: boolean): string[] {
        let mainProps: string[] = ["pedido_disponibilidade_itens_id",
            "ped_itens_id",
            "ped_itens_descricao",
            "quantidade_item_pedido",
            "cont_ped_mod_id",
            "cont_comp_iten_id",
            "pedidos_disponibilidade_itens_quantidade_final_atendida",
        ];

        if (usuarioLiberador) {
            mainProps.push(...[
                "pedido_disponibilidade_itens_quantidade_atendida_simulacao_1",
                "pedido_disponibilidade_itens_quantidade_atendida_simulacao_2",
                "pedido_disponibilidade_itens_quantidade_atendida_simulacao_3",
                "pedido_disponibilidade_itens_quantidade_atendida_simulacao_4",
                "pedido_disponibilidade_itens_quantidade_atendida_simulacao_5",
            ]);
        }
        return mainProps;
    }

    static displayName(usuarioLiberador: boolean): string[] {
        let mainNames: string[] = ['COD. DISPON.',
            'COD. ITEM',
            'DESC. ITEM',
            'QTD. ITEM',
            'COD. MODELO',
            'COMPET. ITEM',
            'QTD. FINAL',];

        if (usuarioLiberador) {
            mainNames.push(...[
                'QTD. SIMU. 1',
                'QTD. SIMU. 2',
                'QTD. SIMU. 3',
                'QTD. SIMU. 4',
                'QTD. SIMU. 5',
            ])
        }
        return mainNames;
    }


    static cellSizes(usuarioLiberador: boolean): string[] {
        let mainSizes: string[] = ['120px',
            '120px',
            '200px',
            '120px',
            '120px',
            '150px',
            '120px',
        ];
        if (usuarioLiberador) {
            mainSizes.push(...['120px',
                '120px',
                '120px',
                '120px',
                '120px',])
        }

        return mainSizes;
    }
}