import { Component, Input, OnInit } from '@angular/core';
import { PrintBoardService } from './print-board.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'print-board',
  templateUrl: './print-board.component.html',
  styleUrls: ['./print-board.component.scss']
})
export class PrintBoardComponent implements OnInit {

  public etiquetas: any = [];

  constructor(
    private printService: PrintBoardService
  ) { }

  ngOnInit() {
    this.printService.getToPrint.subscribe(observer => {
      if (observer) {
        if (observer.length > 0) {
          this.etiquetas = observer;
        } else {
          this.etiquetas = [];
        }
      } else {
        this.etiquetas = [];
      }
    });
  }

}
