import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { IStatusPedido } from '../../interfaces/status_pedido.interface';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app/app.service';
import { StatusPedidoConst } from '../../constants/status_pedido.constant';
import { TableListColumns } from 'src/app/interfaces/table-list.interface';
import { TableEstoqueContants } from '../../constants/table-estoque.constants';
import { DisponibilidadeEstoqueService } from 'src/app/views/shared/services/disponibilidade-estoque.service';
import { Utils } from 'src/app/views/shared/utils';
import { ClienteService } from 'src/app/views/shared/services/clientes.service';
import { ICliente } from 'src/app/views/shared/interfaces/cliente.interface';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IDisponibilidadeEstoque } from '../../interfaces/disponibilidade_estoque.interface';
import { LiberarDisponibilidadeComponent } from '../liberar-disponibilidade/liberar-disponibilidade.component';
import { AjustarPrioridadeComponent } from '../ajustar-prioridade/ajustar-prioridade.component';
import { PERMISSOES } from 'src/app/constants/permissoes';
import { IInstituicao } from '../../interfaces/instituicao.interface';

@Component({
  selector: 'app-disponibilidade-estoque',
  templateUrl: './disponibilidade-estoque.component.html',
  styleUrls: ['./disponibilidade-estoque.component.scss']
})
export class DisponibilidadeEstoqueComponent implements OnInit {

  filtroForm: FormGroup;
  processando: boolean = true;
  temEstoque: boolean = false;
  listaColunasTabela: TableListColumns;
  instituicaoControl = new FormControl();
  estoque: IDisponibilidadeEstoque[] = [];
  statusPedido: IStatusPedido[] = [];
  listaLinhasTabela: Array<any>;
  instituicoes: IInstituicao[] = [];
  filteredInstituicoes: Observable<IInstituicao[]>;
  usuarioLiberador: boolean = false;
  formaAtendimento: string[] = ['0', '1', '2', '3', '4', '5'];

  constructor(private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private router: Router,
    private clienteService: ClienteService,
    private disponibilidadeEstoqueService: DisponibilidadeEstoqueService,
    public dialog: MatDialog,
    private appService: AppService,
  ) {
    this.usuarioLiberador = this.appService.verifyPermission(PERMISSOES.DISPONIBILIDADE_LIBERADOR);
    this.estoque = [];

    this.filtroForm = this.fb.group({
      inst_codigo: [''],
      forma_atendimento: [''],
      status_pedido: [''],
      offset: [0],
      limit: [30],
    });
    this.listaColunasTabela = {
      propName: TableEstoqueContants.propName(this.usuarioLiberador),
      displayName: TableEstoqueContants.displayName(this.usuarioLiberador),
      hide: {
        xs: [false, false, false, false],
        sm: [false, false, false, false],
        md: [false, false, false, false]
      },
      size: TableEstoqueContants.cellSizes(this.usuarioLiberador)
    };
    this.statusPedido = StatusPedidoConst.status;
  }

  ngOnInit() {
    this.obterDadosFiltro();
    this.filteredInstituicoes = this.instituicaoControl.valueChanges.pipe(

      startWith(''),
      map(value => typeof value === 'string' ? value : value.instituicao_localizada),

      map(instituicao_localizada => instituicao_localizada ? this._filter(instituicao_localizada) : this.instituicoes.slice())
    );

    const estoqueTemp = JSON.parse(localStorage.getItem('DisponibilidadeEstoqueList'));

    if (estoqueTemp != null) {
      this.estoque = estoqueTemp;
      this.temEstoque = this.estoque.length > 0;
      this.listaLinhasTabela = this.formatarLinhasTabela();
      this.filtroForm.patchValue({ offset: this.estoque.length })
      this.processando = false;
    }
  }

  private _filter(value: string): IInstituicao[] {
    const filterValue = value.toLowerCase();
    return this.instituicoes.filter(option => option.instituicao_localizada.toLowerCase().includes(filterValue));
  }

  private async obterDadosFiltro() {
    try {
      this.processando = true;
      const instituicaoResult = await this.disponibilidadeEstoqueService.listarInstituicoes();
      this.instituicoes = instituicaoResult.data.data;
    } catch (error) {
      console.log(error);
      this.snackbar.open("Não foi possível obter os dados do filtro. Por favor, recarregue a página!.", 'FECHAR', { duration: 4500 });

    }

  }

  onPesquisarClick() {
    this.filtroForm.patchValue({ offset: 0 })
    this.estoque = [];
    this.buscarEstoque();
  }

  private async buscarEstoque() {
    this.processando = true;
    const filtroData = this.filtroForm.getRawValue();
    const filtroLiberados = this.getStatusPedido(filtroData, StatusPedidoConst.status_categ.liberado);
    const filtroProvisionado = this.getStatusPedido(filtroData, StatusPedidoConst.status_categ.provisionado);
    const instCodigo = this.instituicaoControl.value != null ? this.instituicaoControl.value.inst_codigo : '';

    const filtoCustomizado = {
      pedidos_provisionados: filtroProvisionado,
      pedidos_liberados: filtroLiberados,
      limit: filtroData.limit,
      offset: filtroData.offset,
    };

    if (instCodigo != '') {
      filtoCustomizado['inst_codigo'] = [instCodigo];
    }
    if (filtroData.forma_atendimento != '') {
      filtoCustomizado['forma_atendimento'] = filtroData.forma_atendimento;
    }
    try {
      localStorage.removeItem('DisponibilidadeEstoqueList');
      const result = await this.disponibilidadeEstoqueService.listarDisponibilidade(filtoCustomizado);
      this.estoque = result.data.data;
      localStorage.setItem('DisponibilidadeEstoqueList', JSON.stringify(this.estoque));
    } catch (error) {
      console.log(error);
      this.snackbar.open("Não foi possível obter as disponibilidades de estoque. Por favor, recarregue a página!.", 'FECHAR', { duration: 3500 });
    }

    this.temEstoque = this.estoque.length > 0;
    this.listaLinhasTabela = this.formatarLinhasTabela();
    this.filtroForm.patchValue({ offset: this.estoque.length })
    this.processando = false;
  }

  getStatusPedido(filtroData: any, statusCateg: string) {
    let result = '';
    const statusSelecionados: IStatusPedido[] = filtroData.status_pedido == '' ? [] : filtroData.status_pedido;
    statusSelecionados.forEach(e => {
      if (e.statusCateg == statusCateg) {
        result = result == '' ? e.statusValue : '';
      }
    });
    return result;
  }

  formatarLinhasTabela(tableActions: boolean = true): Array<any> {
    const formatedData = [];
    this.estoque.forEach((estoque) => {

      const corLinha = this.definirCorLinha(estoque);
      formatedData.push({
        clie_nome_fantasia: estoque.clie_nome_fantasia,
        pedido_disponibilidade_id: estoque.pedido_disponibilidade_id,
        ped_id: estoque.ped_id,
        inst_codigo: estoque.inst_codigo,
        inst_nfantasia: estoque.inst_nfantasia,
        inst_localidade: estoque.inst_localidade,
        inst_uf: estoque.inst_uf,
        prod_cate_id: estoque.prod_cate_id,
        prod_cate_descricao: estoque.prod_cate_descricao,
        pedido_disponibilidade_pedido_suspenso: estoque.pedido_disponibilidade_pedido_suspenso == 1 ? 'Sim' : 'Não',
        pedido_disponibilidade_prioridade_provisionamento: estoque.pedido_disponibilidade_prioridade_provisionamento,
        pedido_disponibilidade_pedido_critico: estoque.pedido_disponibilidade_pedido_critico == 1 ? 'Sim' : 'Não',
        pedido_disponibilidade_pedido_liberado: estoque.pedido_disponibilidade_pedido_liberado == 1 ? 'Sim' : 'Não',
        pedido_disponibilidade_prioridade_ajustada: estoque.pedido_disponibilidade_prioridade_ajustada,
        pedido_disponibilidade_simulacao_escolhida: estoque.pedido_disponibilidade_simulacao_escolhida,
        pedido_disponibilidade_passo_simulacao_1: estoque.pedido_disponibilidade_passo_simulacao_1,
        pedido_disponibilidade_passo_simulacao_2: estoque.pedido_disponibilidade_passo_simulacao_2,
        pedido_disponibilidade_passo_simulacao_3: estoque.pedido_disponibilidade_passo_simulacao_3,
        pedido_disponibilidade_passo_simulacao_4: estoque.pedido_disponibilidade_passo_simulacao_4,
        pedido_disponibilidade_passo_simulacao_5: estoque.pedido_disponibilidade_passo_simulacao_5,
        pedido_disponibilidade_data_termino_aguardando_estoque: Utils.formatDate(estoque.pedido_disponibilidade_data_termino_aguardando_estoque),
        pedido_disponibilidade_data_inicio_aguardando_recebimento: Utils.formatDate(estoque.pedido_disponibilidade_data_inicio_aguardando_recebimento),
        pedido_disponibilidade_data_processamento: Utils.formatDate(estoque.pedido_disponibilidade_data_processamento),
        pedido_disponibilidade_data_maxima_provisionamento: Utils.formatDate(estoque.pedido_disponibilidade_data_maxima_provisionamento),
        pedido_disponibilidade_passo: estoque.pedido_disponibilidade_passo,
        rowTextColor: corLinha,
        acoes: {
          check: estoque.pedido_disponibilidade_pedido_liberado != 1 && this.usuarioLiberador,
        }
      });
    });

    return formatedData;
  }

  private definirCorLinha(disponibilidade: IDisponibilidadeEstoque) {
    if (disponibilidade.pedido_disponibilidade_pedido_liberado != null
      && disponibilidade.pedido_disponibilidade_pedido_liberado == 1) {
      return '#008000';
    } else if (disponibilidade.pedido_disponibilidade_pedido_suspenso != null
      && disponibilidade.pedido_disponibilidade_pedido_suspenso == 1) {
      return '#ff0000';
    } {
      return '#000000';
    }
  }

  limparFiltros() {
    this.filtroForm.patchValue({
      prod_cate_id: '',
      inst_codigo: '',
      forma_atendimento: '',
      status_pedido: '',
      cli_id: '',
      offset: 0,
      limit: 30
    });
    this.instituicaoControl.patchValue('');
  }


  async onClickRow(event) {
    const codigoDisponibilidade = event.pedido_disponibilidade_id;

    this.router.navigate([`app/disponibilidade-estoque/${codigoDisponibilidade}/detalhamento`]);
  }

  async onActionRowClick(event) {
    const disponibilidadeId = event.data.pedido_disponibilidade_id;

    switch (event.type) {
      case 'check':
        this.liberarPedido(disponibilidadeId);
        break;

    }
  }

  displayFn(cliente: ICliente): string {
    return cliente ? cliente.clie_nome_fantasia : undefined;
  }

  displayFnInst(instituicao: IInstituicao): string {
    return instituicao ? instituicao.instituicao_localizada : undefined;
  }

  private async liberar(disponibilidadeId: number, simulacaoEscolhida: string) {
    try {
      await this.disponibilidadeEstoqueService.liberarPedido(
        disponibilidadeId.toString(), simulacaoEscolhida.toString());
      await this.snackbar.open("Pedido liberado co sucesso", 'FECHAR', { duration: 4500 });
    } catch (error) {
      console.log(error);
      this.snackbar.open("Não foi possível liberar o pedido. Por favor, tente novamente.", 'FECHAR', { duration: 4500 });
    }
  }

  private liberarPedido(disponibilidadeId: number) {
    const dialogRef = this.dialog.open(LiberarDisponibilidadeComponent, {
      maxWidth: '600px',
      data: disponibilidadeId
    });

    dialogRef.afterClosed().subscribe(simuEscolhida => {
      if (simuEscolhida) {
        this.liberar(disponibilidadeId, simuEscolhida).then(result => {
          this.onPesquisarClick();
        });
      }
    });
  }

  private editarPrioridadeAjustada() {
    const dialogRef = this.dialog.open(AjustarPrioridadeComponent, {
      minWidth: 500,
      data: this.estoque
    });

    dialogRef.afterClosed().subscribe(disponibilidadeAjustada => {
      if (disponibilidadeAjustada) {
        disponibilidadeAjustada.sort((a, b) =>
          Number(a.pedido_disponibilidade_prioridade_ajustada) - Number(b.pedido_disponibilidade_prioridade_ajustada)
        );
        this.estoque = disponibilidadeAjustada;
        this.processando = true;
        this.listaLinhasTabela = this.formatarLinhasTabela();
        this.processando = false;

      }
    });
  }


}


