export class TableComposicao {
    static propName(usuarioLiberador: boolean): string[] {
        let mainProps: string[] = ["pedidos_disponibilidade_composicao_id",
            "ped_comp_id",
            "prod_id",
            "prod_descricao",
            "ped_comp_unidade",
            "cont_comp_composicao_id",
            "pedido_disponibilidade_composicao_produto_atendido_final",
        ];

        if (usuarioLiberador) {
            mainProps.push(...["pedido_disponibilidade_composicao_produto_atendido_simulacao_1",
                "pedido_disponibilidade_composicao_produto_atendido_simulacao_2",
                "pedido_disponibilidade_composicao_produto_atendido_simulacao_3",
                "pedido_disponibilidade_composicao_produto_atendido_simulacao_4",
                "pedido_disponibilidade_composicao_produto_atendido_simulacao_5",]);
        }
        return mainProps;
    }

    static displayName(usuarioLiberador: boolean): string[] {
        let mainNames: string[] = ['COD. DISPO. COMPO.',
            'COD. COMPO',
            'PROD. COD.',
            'PROD. DESC.',
            'PROD. UNI.',
            'COMP. COMPO.',
            'Atend. Final',
        ];

        if (usuarioLiberador) {
            mainNames.push(...[
                'QTD. SIMU. 1',
                'QTD. SIMU. 2',
                'QTD. SIMU. 3',
                'QTD. SIMU. 4',
                'QTD. SIMU. 5',]);
        }
        return mainNames;

    }
    static cellSizes(usuarioLiberador: boolean): string[] {
        let mainSizes: string[] = [
            '120px',
            '120px',
            '120px',
            '200px',
            '120px',
            '150px',
            '120px',
        ];

        if (usuarioLiberador) {
            mainSizes.push(...[
                '120px',
                '120px',
                '120px',
                '120px',
                '120px',]);
        }
        return mainSizes;

    }
}