import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkCheckComponent } from './network-check.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressBarModule
  ],
  declarations: [NetworkCheckComponent],
  exports: [NetworkCheckComponent]
})
export class NetworkCheckModule { }
