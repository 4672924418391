import { RouterModule } from '@angular/router';
import { MainContainerWrapperComponent } from './main-container-wrapper.component';
import { FullscreenLoaderComponent } from './fullscreen-loader/fullscreen-loader.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatButtonModule, MatProgressSpinnerModule, MatMenuModule, MatSidenavModule } from '@angular/material';

import { SidenavService } from '../services/sidenav.service';
import { SidenavModule } from '../sidenav/sidenav.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { NetworkCheckModule } from '../network-check/network-check.module';

@NgModule({
  declarations: [
      FullscreenLoaderComponent,
      MainContainerWrapperComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSidenavModule,
    SidenavModule,
    ToolbarModule,
    RouterModule,
    NetworkCheckModule
  ],
  exports: [MainContainerWrapperComponent],
  providers: [SidenavService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainContainerWrapperModule { }
