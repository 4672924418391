import { FullscreenLoaderService } from 'src/app/views/shared/services/fullscreen-loader.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sem-acesso',
  templateUrl: './sem-acesso.component.html',
  styleUrls: ['./sem-acesso.component.scss']
})
export class SemAcessoComponent implements OnInit {

  constructor(
    private fullscreenLoaderService: FullscreenLoaderService
  ) { }

  ngOnInit() {
    this.fullscreenLoaderService.hide();
  }

}
