import { FullscreenLoaderService } from 'src/app/views/shared/services/fullscreen-loader.service';
import { Injectable } from '@angular/core';
import { CHAVE_ERP_COSMOS } from '../../constants/chaves-sistemas';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { ROUTES_NO_AUTH } from '../../constants/routes-no-auth';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsuaPerfilAtivoStorage } from 'src/app/interfaces/storage.interface';

@Injectable()
export class AppService {

  private scrollBehaviorSubject: BehaviorSubject<any>;
  scroll: Observable<any>;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private loading: FullscreenLoaderService
  ) {
    this.scrollBehaviorSubject = new BehaviorSubject(null);
    this.scroll = this.scrollBehaviorSubject.asObservable();
  }

  verifyPermission(codigoRotina: number): boolean {
    let temPermissao = false;
    const usuarioPerfil: UsuaPerfilAtivoStorage = JSON.parse(localStorage.getItem('usua_perfil_ativo'));

    const rotina = usuarioPerfil.rotinas.find((elem) => {
      return Number(elem) === codigoRotina;
    });

    if (rotina) {
      temPermissao = true;
    }

    return temPermissao;
  }

  redirectToHome() {
    this.router.navigateByUrl(`/app/gerenciador-agregadores`);
  }

  axiosSetBaseEndpoint(endpoint: string) {
    axios.defaults.baseURL = endpoint;
  }

  axiosRegisterInterceptors() {
    axios.interceptors.request.use(
      config => {
        const url = config.baseURL + config.url;
        const isNoAuthRoute = ROUTES_NO_AUTH.find((route) => {
          return url === `${environment.agenda.back}/${route}`;
        });

        if (!isNoAuthRoute) {
          const token = localStorage.getItem('token');
          const usuarioPerfilAtivo: UsuaPerfilAtivoStorage = JSON.parse(localStorage.getItem('usua_perfil_ativo'));

          const headers = {
            'Authorization': config.headers.Authorization ? config.headers.Authorization : token,
            'Inst-Codigo': config.headers.hasOwnProperty('Inst-Codigo') ? config.headers['Inst-Codigo'] : null
          };

          if (usuarioPerfilAtivo && !headers['Inst-Codigo']) {
            headers['Inst-Codigo'] = usuarioPerfilAtivo.instituicao.inst_codigo;
          }

          config.headers = headers;
          this.loading.show();
        }

        return config;
      },
      err => {
        this.loading.hide();
        return Promise.reject(err.response);
      }
    );
  }

  axiosErrorHandler() {
    axios.interceptors.response.use(
      (res) => {
        this.loading.hide();
        if (res.headers.hasOwnProperty('token')) {
          localStorage.setItem('token', res.headers.token);
        }
        return res;
      },
      (err) => {
        this.loading.hide();
        if (err.response) {
          let message = err.response.data.message;

          if (message instanceof Array) {
            if (typeof message[0] !== 'string') {
              message = message[0].descricaoErro;
            } else {
              message = message[0];
            }
          }

          if (err.response.status === 500) {
            this.snackBar.open('Ocorreu um erro interno no processamento da requisição.', 'Fechar', {
              duration: 3500,
              horizontalPosition : 'center',
              verticalPosition: 'bottom'
            });
          } else {
            this.snackBar.open(message, 'Fechar', { duration: 3500 });
            if (message === 'Token expirado') {
              this.authService.logout();
            }
          }
        }

        return Promise.reject(err.response);
      }
    );
  }

  scrollTracker($event) {
    this.scrollBehaviorSubject.next($event);
  }

  validCPF(cpf: string) {
    cpf = cpf.replace(/\.|\-/g, '');

    let soma = 0;
    let resto;
    if (cpf.replace(/(\d)\1{10}/g, '').length === 0) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return false;
    }

    return true;
  }

}
