import { FilterPipe } from './../../../pipes/filter.pipe';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { SidenavComponent } from './sidenav.component';

@NgModule({
  declarations: [
      SidenavItemComponent,
      SidenavComponent,
      FilterPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule
  ],
  exports: [SidenavComponent],
  providers: [],
})
export class SidenavModule { }
