export const CHAVE_SEM_CHAMADA = '1b6453892473a467d07372d45eb05abc2031647a';
export const CHAVE_CORRIGE = 'ac3478d69a3c81fa62e60f5c3696165a4e5e6ac4';
export const CHAVE_CENSO = 'de870f876f6ddbd5078a73b535b2e727e13c650a';
export const CHAVE_PLATAFORMA = '7b74d5513c1790e10ff3edf50a0f4a6349a7f7db';
export const CHAVE_AGENDA = 'cf0645b39f53463ecc63a067f6f9b0df05644514';
export const CHAVE_COMUNICADO = 'eef9c9c426d1fe9184a138a91ed1a273a13d7901';
export const CHAVE_ESTATISTICAS = 'c1e2336914d9159edbb037d9fec4dca4dba0558a';
export const CHAVE_PDVWEB = 'e99c749154e73206c101fd029d3e8b8782f22427';
export const CHAVE_LOJA = '7de1216b162128efa39bcb16b632dca9f8550778';
export const CHAVE_ERP_COSMOS = '7f52ce429b832bcdf8bfbe5dfba486b365bfebb3';
