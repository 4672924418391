import * as moment from "moment";

export class Utils {
    static formatDate(date?: string) {
        if (date != null) {
            return moment(date).
                format('DD/MM/YYYY');
        } else {
            return '';
        }
    }
    static formatDateTime(date?: string) {
        if (date != null) {
            return moment(date).
                format('DD/MM/YYYY HH:MM:SS');
        } else {
            return '';
        }
    }
    static formatDateToBack(date?: string) {
        if (date != null) {
            return moment(date).
                format('YYYY-MM-DD');
        } else {
            return '';
        }
    }
}