import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-informacoes-adicionais',
  templateUrl: './informacoes-adicionais.component.html',
  styleUrls: ['./informacoes-adicionais.component.scss']
})
export class InformacoesAdicionaisComponent implements OnInit {

  public criado_por: any | null = null;
  public desativado_por: any | null = null;

  constructor(
    public dialogRef: MatDialogRef<InformacoesAdicionaisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      const criado_por = Object.assign({}, this.data.criado_por);
      const desativado_por = Object.assign({}, this.data.desativado_por);
      if (Object.keys(criado_por).length > 0) {
        this.criado_por = this.data.criado_por;
      }
      if (Object.keys(desativado_por).length > 0) {
        this.desativado_por = this.data.desativado_por;
      }
    }
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
