let truncate = (name, len) => {
  let ext = name
    .substring(name.lastIndexOf('.') + 1, name.length)
    .toLowerCase();
  let filename = name.replace('.' + ext, '');
  if (filename.length <= len) {
    return name;
  }
  filename = filename.substr(0, len) + (name.length > len ? '...' : '');
  return filename;
};

export const Functions = {
  truncate,
};
