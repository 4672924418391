export const PERMISSOES = {
  BE_AWESOME: 100,
  DO_AMAZING_CODE: 101,
  LISTAGEM_AGREGADORES: 1263,
  CRIAR_AGREGADOR: 1264,
  LISTAGEM_USUARIOS: 1310,
  CRIAR_ATUALIZAR_USUARIO: 1311,
  PERMITE_DESATIVAR_USER: 1339,
  ACESSAR_PERMISSOES: 1340,
  LISTAGEM_OCORRENCIAS: 1617,
  CRIAR_OCORRENCIAS: 1618,
  CANCELAR_OCORRENCIAS: 1619,
  FINALIZAR_OCORRENCIAS: 1620,
  EDITAR_OCORRENCIAS: 1621,
  DETALHAMENTO_OCORRENCIA: 1622,
  LISTAGEM_MERCADORIAS: 1623,
  EDITAR_MERCADORIAS: 1624,
  CANCELAR_MERCADORIAS: 1625,
  CRIAR_MERCADORIAS: 1626,
  DETALHAMENTO_MERCADORIAS: 1627,
  LISTAGEM_DISPONIBILIDADE: 1628,
  DETALHAMENTO_DISPONIBILIDADE: 1629,
  DISPONIBILIDADE_LIBERADOR: 1630,
};
