import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-liberar-disponibilidade',
  templateUrl: './liberar-disponibilidade.component.html',
  styleUrls: ['./liberar-disponibilidade.component.scss']
})
export class LiberarDisponibilidadeComponent implements OnInit {

  simulacaoEscolhida: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    public dialogRef: MatDialogRef<LiberarDisponibilidadeComponent>,) { }

  ngOnInit() {
  }

  onBackClick() {
    this.dialogRef.close(false);
  }

  onConfirmClick() {
    this.dialogRef.close(this.simulacaoEscolhida);
  }

}
