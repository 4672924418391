import { FullscreenLoaderService } from '../../services/fullscreen-loader.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from './loader';

@Component({
  selector: 'fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss'],
})
export class FullscreenLoaderComponent implements OnInit, OnDestroy {
  showLoader: boolean;
  private subscription: Subscription;

  constructor(private loaderService: FullscreenLoaderService) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.showLoader = state.show;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
