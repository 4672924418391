import { AppService } from './services/app/app.service';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appService: AppService
  ) {
    this.appService.axiosSetBaseEndpoint(environment.erpCosmos.back);
    this.appService.axiosRegisterInterceptors();
    this.appService.axiosErrorHandler();
    this.registerIcons();
  }

  private registerIcons() {
    this.matIconRegistry.addSvgIcon(
      `arrow_left`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `arrow_right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `arrow_up`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `arrow_down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_down.svg')
    );
  }
}
