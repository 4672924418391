import { FlexLayoutModule } from '@angular/flex-layout';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DisponibilidadeEstoqueComponent } from './components/disponibilidade-estoque/disponibilidade-estoque.component';
import { DisponibilidadeEstoqueRoutes } from './disponibilidade-estoque.routing';
import { MatAutocompleteModule, MatDialogModule, MatExpansionModule, MatTableModule } from '@angular/material';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableListModule } from '../../shared/table-list/table-list.module';
import { DisponibilidadeDetalhadaComponent } from './components/disponibilidade-detalhada/disponibilidade-detalhada.component';
import { LiberarDisponibilidadeComponent } from './components/liberar-disponibilidade/liberar-disponibilidade.component';
import { AjustarPrioridadeComponent } from './components/ajustar-prioridade/ajustar-prioridade.component';
import { DisponibilidadeEstoqueService } from '../../shared/services/disponibilidade-estoque.service';


@NgModule({
    declarations: [
        DisponibilidadeEstoqueComponent,
        DisponibilidadeDetalhadaComponent,
        LiberarDisponibilidadeComponent,
        AjustarPrioridadeComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(DisponibilidadeEstoqueRoutes),
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        FlexLayoutModule,
        MatCardModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        ScrollingModule,
        TableListModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatDialogModule,
    ],
    exports: [
        DisponibilidadeEstoqueComponent,
    ],
    entryComponents: [
        DisponibilidadeEstoqueComponent,
        LiberarDisponibilidadeComponent,
        AjustarPrioridadeComponent,
    ],
    providers: [DisponibilidadeEstoqueService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DisponibilidadeEstoqueModule { }
