import { Injectable } from "@angular/core";
import axios from "axios";

@Injectable({
    providedIn: 'root'
})
export class ClienteService {
    constructor() { }

    public listarClientes(): Promise<any> {

        return axios.get(`/clientes`);
    }
}