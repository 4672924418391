import { UserMenuComponent } from './user-menu/user-menu.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatToolbarModule, MatMenuModule, MatIconModule, MatButtonModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { SidenavModule } from '../sidenav/sidenav.module';

@NgModule({
  declarations: [
    ToolbarComponent,
    AppMenuComponent,
    UserMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule
  ],
  exports: [
    ToolbarComponent
  ],
  providers: [],
})
export class ToolbarModule { }
