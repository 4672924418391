import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-desativar-dialog',
  templateUrl: './desativar-dialog.component.html',
  styleUrls: ['./desativar-dialog.component.scss']
})
export class DesativarDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DesativarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public confirmar(): void {
    this.dialogRef.close(true);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

}
