import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintBoardComponent } from './print-board.component';
import { BarcodeModule } from '../barcode/barcode.module';
import { PrintBoardService } from './print-board.service';

@NgModule({
  imports: [
    CommonModule,
    BarcodeModule
  ],
  declarations: [PrintBoardComponent],
  exports: [PrintBoardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [PrintBoardService]
})
export class PrintBoardModule { }
