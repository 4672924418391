import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionGuard } from './permission/permission.guard';
import { HomeGuard } from './home/home.guard';
import { AuthGuard, } from './auth/auth.guard';
import { IsAuthenticatedGuard } from './auth/isauth.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    PermissionGuard,
    HomeGuard,
    AuthGuard,
    IsAuthenticatedGuard
  ],
})
export class GuardsModule { }
