import {
  CHAVE_SEM_CHAMADA, CHAVE_CENSO, CHAVE_CORRIGE, CHAVE_AGENDA,
  CHAVE_COMUNICADO, CHAVE_LOJA, CHAVE_PDVWEB, CHAVE_PLATAFORMA
} from '../../../../constants/chaves-sistemas';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

  escolasOptions: Array<any>;
  escolaSelecionada: any;
  sistemasOptions: Array<any>;
  usuario: String;

  private subscription: Subscription;

  constructor() {
    this.escolaSelecionada = { nome: '' };
  }

  ngOnInit() {
    const instCodigo = localStorage.getItem('inst_codigo');
    this.escolasOptions = JSON.parse(localStorage.getItem('escolas'));
    if (this.escolasOptions) {
      this.escolaSelecionada = this.escolasOptions.find(elem => {
        return String(elem.codigo) === String(instCodigo);
      });

      this.escolaSelecionada.sistemas = this.escolaSelecionada.sistemas.filter((elem) => {
        return elem.chave !== CHAVE_CENSO;
      });

      this.sistemasOptions = this.escolaSelecionada.sistemas.map(element => {
        const item = {
          chave: element.chave,
          nome: element.nome,
          imagem: '',
          sistemaUrl: element.sistemaUrl
        };
        switch (element.chave) {
          case CHAVE_PLATAFORMA:
            item.imagem = '../../../assets/logos/pluriconnect.png';
            break;
          case CHAVE_SEM_CHAMADA:
            item.imagem = '../../../assets/logos/semchamada.png';
            break;
          case CHAVE_CORRIGE:
            item.imagem = '../../../assets/logos/corrige.png';
            break;
          case CHAVE_AGENDA:
            item.imagem = '../../../assets/logos/agenda.png';
            break;
          case CHAVE_COMUNICADO:
            item.imagem = '../../../assets/logos/comunicados.png';
            break;
          case CHAVE_LOJA:
            item.imagem = '../../../assets/logos/loja.png';
            break;
          case CHAVE_PDVWEB:
            item.imagem = '../../../assets/logos/pdvweb.png';
            break;
          default:
            item.imagem = '../../../assets/logos/pluri.png';
            break;
        }
        return item;
      });

      this.usuario = JSON.parse(localStorage.getItem('usuario')).nome;
    }
  }

  changeSistema(system) {
    const token = localStorage.getItem('token');
    // indexEscola é um indice legado, nas próximas versões deve-se remover esse indice
    const profileActive = localStorage.getItem('perfil_ativo') || localStorage.getItem('indexEscola');
    let inst_codigo = localStorage.getItem('inst_codigo');
    inst_codigo = inst_codigo.replace(/"/g, '');

    switch (system.chave) {
      case CHAVE_PLATAFORMA:
      case CHAVE_COMUNICADO:
      case CHAVE_AGENDA:
      case CHAVE_SEM_CHAMADA:
        window.open(`${system.sistemaUrl}/auth?t=${token}&ic=${inst_codigo}&pi=${profileActive}`, '_self');
        break;
      case CHAVE_CORRIGE:
        window.open(`${system.sistemaUrl}/#/acessar?token=${token}&inst_codigo=${inst_codigo}&perfil_index=${profileActive}`, '_self');
        break;
      case CHAVE_PDVWEB:
        window.open(`${system.sistemaUrl}/LoginPDV.aspx?token=${token}&inst_codigo=${inst_codigo}&perfil_index=${profileActive}`, '_self');
        break;
      case CHAVE_LOJA:
        window.open(`${system.sistemaUrl}/EntryLoginApp.aspx?token=${token}&inst_codigo=${inst_codigo}&perfil_index=${profileActive}`,
          '_self');
        break;
      default:
        window.open(`${system.sistemaUrl}/#/acessar?token=${token}&inst_codigo=${inst_codigo}&perfil_index=${profileActive}`, '_self');
        break;
    }
  }

}
