export const environment = {
  production: false,
  agenda: {
    back: (<any>window)._env.agenda.back,
    front: (<any>window)._env.agenda.front
  },
  pluriConnect: {
    back: (<any>window)._env.pluriConnect.back,
    front: (<any>window)._env.pluriConnect.front
  },
  comunicacao: {
    back: (<any>window)._env.comunicacao.back,
    front: (<any>window)._env.comunicacao.front
  },
  censo: {
    back: (<any>window)._env.censo.back,
    front: (<any>window)._env.censo.front
  },
  semChamada: {
    back: (<any>window)._env.semChamada.back,
    front: (<any>window)._env.semChamada.front
  },
  corrige: {
    back: (<any>window)._env.corrige.back,
    front: (<any>window)._env.corrige.front
  },
  pdv: {
    back: (<any>window)._env.pdv.back,
    front: (<any>window)._env.pdv.front
  },
  loja: {
    back: (<any>window)._env.loja.back,
    front: (<any>window)._env.loja.front
  },
  pluriId: {
    back: (<any>window)._env.pluriId.back,
    front: (<any>window)._env.pluriId.front
  },
  erpCosmos: {
    back: (<any>window)._env.erpCosmos.back,
    front: (<any>window)._env.erpCosmos.front
  },
  ceps: {
    back: (<any>window)._env.ceps.back,
  }
};
